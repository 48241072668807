<template>
	<div>
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%;">
					<a-row type="flex" justify="space-between">
						<a-col span="12">
							<common-page-btn @category="category" @createShare="createShare"></common-page-btn>
						</a-col>
						<a-col span="10">
							<a-input v-model="param.keyword" allowClear search @pressEnter="getShareList" @on-clear="getShareList" placeholder="关键词查询"></a-input>
						</a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>

		<div v-if="isTable">
			<a-row :gutter="[16, 16]" type="flex" justify="start">
				<a-col v-for="share in data.records" :key="share.shareId">
					<a-card style="width: 300px">
						<template slot="title">
							<div>{{ share.name }}</div>
						</template>
						<div slot="extra">
							<a-tooltip title="修改"><a-icon style="margin-right:10px" v-has="'space:delShare'" @click="editshare(share)" type="edit" /></a-tooltip>
							<a-tooltip title="删除"><a-icon v-has="'space:delShare'" @click="delShare(share)" type="delete" /></a-tooltip>
						</div>
						<div style="text-align:center;margin-bottom:5px">
							<!-- <a-icon type="codepen-square" theme="filled" /> -->
							<a-icon style="font-size:60px;color:rgb(24,144,255);" type="folder-open" theme="filled" />
						</div>
						<p style="text-align:center">创建时间：{{ share.createTime }}</p>
					</a-card>
				</a-col>
			</a-row>
			<a-row>
				<a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
					<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext" @showSizeChange="setLimit" :current="data.current" />
				</a-col>
			</a-row>
		</div>

		<setting-form @add="add" :sharedata="sharedata" :isEdit="isEdit" v-if="!isTable" ref="settingForm" @success="doRefresh"></setting-form>
		<category ref="category"></category>
	</div>
</template>

<script>
import settingForm from './components/settingForm.vue';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import category from './components/category.vue';
const columns = [
	{ title: '文件夹名称', dataIndex: 'name' },
	{ title: '所属单位', dataIndex: 'organName' },
	{ title: '可查看的人员', dataIndex: 'openScope' },
	{ title: '创建时间', dataIndex: 'createTime' },
	{ title: '是否可以复制', scopedSlots: { customRender: 'isCopy' } },
	{ title: '是否可以查看', scopedSlots: { customRender: 'isRead' } },
	{ title: '操作', scopedSlots: { customRender: 'action' } }
];

export default {
	components: { settingForm, category },
	data() {
		return {
			isTable: true,
			visible: false,
			isShow: true,
			shareId: '',
			organList: apiUtil.getOrganList(),
			data: {},
			categorydata: [],
			columns,
			isEdit: false,
			pageSize: 10,
			total: '',
			sharedata: {},
			param: {
				page: 1,
				limit: 20,
				keyword: '',
				organId: ''
			}
		};
	},
	mounted() {
		this.getShareList();
	},
	methods: {
		...apiUtil,
		// 获取共享空间
		getShareList() {
			utils.showSpin();
			this.param.organId = '';
			this.http
				.get('/platform/scope/share', this.param)
				.then(ret => {
					this.data = ret.data;
					this.total = ret.data.total;
					utils.closeSpin();
				})
				.catch(err => {
					utils.closeSpin();
				});
		},

		// 筛选条件变化重新获取数据
		doRefresh() {
			this.param.current = 1;
			this.getShareList();
		},
		// 分类设置
		category() {
			this.$refs.category._show();
			this.isTable = true;
			this.param.cruuent = 1;
		},
		// 共享空间设置
		share() {
			this.isTable = true;
			this.getShareList();
		},

		// 添加完成以后
		add() {
			this.isTable = true;
			this.getShareList();
		},
		// 创建共享空间
		createShare() {
			this.isEdit = false;
			this.isTable = false;
			this.getShareList();
		},
		// 删除共享空间
		delShare(e) {
			this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
				utils.showSpin();
				this.http
					.delete('/platform/scope/delShare/' + e.shareId)
					.then(ret => {
						if (ret.code == 200) {
							this.utils.success('操作成功！').then(() => {
								this.getShareList();
							});
						}
					})
					.catch(err => {});
			});
		},

		// 编辑
		editshare(share) {
			this.sharedata = share;
			this.isTable = false;
			this.isEdit = true;
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getShareList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getShareList();
		}
	}
};
</script>

<style></style>
